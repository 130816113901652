.display-enter {
    opacity: 0;
}

.display-enter-active {
    opacity: 1;
    transition: opacity 350ms;
}

.display-exit {
    opacity: 1;
}

.display-exit-active {
    opacity: 0;
    transition: opacity 350ms;
}

.navbar-nav .nav-link {
    position: relative;
}

.navbar-nav .nav-link:before {
    /* active state underline */
    content: '';
    width: 80%;
    height: 1px;
    background: currentColor;
    opacity: 1;
    position: absolute;
    display: none;
    bottom: 0.4rem;
    left: 10%;
}

.navbar-nav .nav-link.active:before {
    display: block;
}






@media (max-width: 992px) {

    /* .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 10;
    } */

    /* .navbar {
        position: relative;
    } */

    .navbar-collapse {
        /* position: absolute; */
        z-index: 20;
        background-color: inherit;
        height: 30vh;
    }

    /* .collapse.show {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: 1000;
    } */



    .navbar-nav .nav-link:before {
        /* active state underline */
        width: 100%;
        bottom: 0.4rem;
        left: 0;
        margin-right: 0 !important;
    }

    #navbar-align-items-center {
        align-items: center;
    }

    /* #navbar-contact-button-center {
        display: flex;
        justify-content: center;

    } */
}

@media only screen and (max-width: 63.9375rem) {

    .cs-toggle {
        /* 44px - 48px */
        width: clamp(3.5rem, 6vw, 4rem);
        height: clamp(3.5rem, 6vw, 4rem);
        /* margin: 0 0 0 auto; */
        border-radius: 0.25rem;
        background-color: transparent;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 30;

    }

    .cs-active .cs-line1 {
        top: 50%;
        transform: translate(-50%, -50%) rotate(225deg);
    }

    .cs-active .cs-line2 {
        top: 50%;
        transform-origin: center;
        transform: translate(-50%, -50%) translateY(0) rotate(-225deg);
    }

    .cs-active .cs-line3 {
        bottom: 100%;
        opacity: 0;
    }

    .cs-box {
        /* 24px - 28px */
        width: clamp(1.5rem, 2vw, 1.75rem);
        /* 14px - 16px */
        height: clamp(0.875rem, 1.5vw, 1rem);
        position: relative;
    }

    .cs-line {
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background-color: black;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    .cs-line1 {
        top: 0;
        transform-origin: center;
        transition: transform .5s, top .3S, left .3S;
        animation-duration: .7s;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
        animation-direction: normal;
    }

    .cs-line2 {
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        transition: top .3s, left .3s, transform .5s;
        animation-duration: .7s;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
        animation-direction: normal;
    }

    .cs-line3 {
        bottom: 0;
        transition: bottom .3s, opacity .3s;
    }
}


/* Common styles for #cs-navigation */
#cs-navigation {
    /* font-family: 'Roboto', 'Arial', sans-serif; */
    width: 100%;
    padding: 0.75rem 1rem;
    box-sizing: border-box;
    background-color: #fff;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
}

/* Common styles for #cs-navigation .cs-logo */
#cs-navigation .cs-logo {
    height: 100%;
    /* margin: 0 auto 0 0; */
    padding: 0;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

/* Common styles for #cs-navigation .cs-logo img */
/* #cs-navigation .cs-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
} */

/* Mobile - 1023px */
@media only screen and (max-width: 63.9375rem) {
    body.cs-open {
        overflow: hidden;
    }

    #cs-navigation {
        /* remove the font family so the Stitch inherits the fonts from your global stylesheet */
        /* font-family: 'Roboto', 'Arial', sans-serif; */
        width: 100%;
        padding: 0.75rem 1rem;
        /* prevents padding from affecting height and width */
        box-sizing: border-box;
        background-color: #fff;
        /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
        position: fixed;
        z-index: 10000;
        left: 0;
        top: 0;
    }

    #cs-navigation:before {
        content: '';
        width: 100%;
        height: 0vh;
        background: rgba(0, 0, 0, 0.6);
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        position: absolute;
        display: block;
        top: 100%;
        right: 0;
        z-index: -1100;
        opacity: 0;
        transition: height .5s, opacity .5s;
    }

    #cs-navigation.cs-active:before {
        height: 150vh;
        opacity: 0.1;
    }

    #cs-navigation.cs-active .cs-ul-wrapper {
        opacity: 1;
        transform: scaleY(1);
        transition-delay: .15s;
    }

    #cs-navigation.cs-active .cs-li {
        transform: translateY(0);
        opacity: 1;
    }

    #cs-navigation .cs-container {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    #cs-navigation .cs-logo {
        /* width: 40%; */
        /* max-width: 9.125rem; */
        height: 100%;
        /* margin: 0 auto 0 0; */
        padding: 0;
        /* prevents padding from affecting height and width */
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
    }

    #cs-navigation .cs-logo img {
        width: 100%;
        height: 100%;
        /* ensures the image never overflows the container. It stays contained within it's width and height and expands to fill it then stops once it reaches an edge */
        object-fit: contain;
    }

    #cs-navigation .cs-toggle {
        /* 44px - 48px */
        width: clamp(3.5rem, 6vw, 4rem);
        height: clamp(3.5rem, 6vw, 4rem);
        margin: 0 0 0 auto;
        border-radius: 0.25rem;
        background-color: transparent;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #cs-navigation .cs-active .cs-line1 {
        top: 50%;
        transform: translate(-50%, -50%) rotate(225deg);
    }

    #cs-navigation .cs-active .cs-line2 {
        top: 50%;
        transform-origin: center;
        transform: translate(-50%, -50%) translateY(0) rotate(-225deg);
    }

    #cs-navigation .cs-active .cs-line3 {
        bottom: 100%;
        opacity: 0;
    }

    #cs-navigation .cs-box {
        /* 24px - 28px */
        width: clamp(1.5rem, 2vw, 1.75rem);
        /* 14px - 16px */
        height: clamp(0.875rem, 1.5vw, 1rem);
        position: relative;
    }

    #cs-navigation .cs-line {
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background-color: #1a1a1a;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    #cs-navigation .cs-line1 {
        top: 0;
        transform-origin: center;
        transition: transform .5s, top .3S, left .3S;
        animation-duration: .7s;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
        animation-direction: normal;
    }

    #cs-navigation .cs-line2 {
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        transition: top .3s, left .3s, transform .5s;
        animation-duration: .7s;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
        animation-direction: normal;
    }

    #cs-navigation .cs-line3 {
        bottom: 0;
        transition: bottom .3s, opacity .3s;
    }

    #cs-navigation .cs-ul-wrapper {
        width: 100%;
        height: auto;
        padding-bottom: 2.4em;
        opacity: 0;
        /* background-color: rgba(255,255,255,0.8);
        box-shadow: inset rgba(0, 0, 0, 0.1) 0px 8px 8px; */
        overflow: hidden;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: -1;
        transform: scaleY(0);
        transform-origin: top;
        transition: transform .4s, opacity .3s;
        --bs-bg-opacity: 1;
        background-color: rgba(var(--bs-light-light-rgb), var(--bs-bg-opacity)) !important;
    }

    #cs-navigation .cs-ul {
        margin: 0;
        padding: 3rem 0 0 0;
        width: 100%;
        height: auto;
        max-height: 65vh;
        overflow: scroll;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: center;
        gap: 1.25rem;
    }

    #cs-navigation .cs-li {
        width: 100%;
        text-align: center;
        list-style: none;
        margin-right: 0;
        /* transition from these values */
        transform: translateY(-4.375rem);
        opacity: 0;
        transition: transform .6s, opacity .9s;
    }

    #cs-navigation .cs-li:nth-of-type(1) {
        transition-delay: .05s;
    }

    #cs-navigation .cs-li:nth-of-type(2) {
        transition-delay: .1s;
    }

    #cs-navigation .cs-li:nth-of-type(3) {
        transition-delay: .15s;
    }

    #cs-navigation .cs-li:nth-of-type(4) {
        transition-delay: .2s;
    }

    #cs-navigation .cs-li:nth-of-type(5) {
        transition-delay: .25s;
    }

    #cs-navigation .cs-li:nth-of-type(6) {
        transition-delay: .3s;
    }

    #cs-navigation .cs-li:nth-of-type(7) {
        transition-delay: .35s;
    }

    #cs-navigation .cs-li:nth-of-type(8) {
        transition-delay: .4s;
    }

    #cs-navigation .cs-li:nth-of-type(9) {
        transition-delay: .45s;
    }

    #cs-navigation .cs-li:nth-of-type(10) {
        transition-delay: .5s;
    }

    #cs-navigation .cs-li:nth-of-type(11) {
        transition-delay: .55s;
    }

    #cs-navigation .cs-li:nth-of-type(12) {
        transition-delay: .6s;
    }

    #cs-navigation .cs-li:nth-of-type(13) {
        transition-delay: .65s;
    }

    #cs-navigation .cs-li-link {
        /* 16px - 24px */
        font-size: clamp(1rem, 2.5vw, 1.5rem);
        line-height: 1.2em;
        text-decoration: none;
        margin: 0;
        color: var(--headerColor);
        display: inline-block;
        position: relative;
    }

    #cs-navigation .cs-li-link:before {
        /* active state underline */
        content: '';
        width: 100%;
        height: 1px;
        background: black;
        opacity: 1;
        position: absolute;
        display: none;
        bottom: -0.125rem;
        left: 0;
    }

    #cs-navigation .cs-li-link.cs-active:before {
        display: block;
    }
}

/* Small Desktop - 1024px */
@media only screen and (min-width: 64rem) {
    #cs-navigation {
        /* remove the font family so the Stitch inherits the fonts from your global stylesheet */
        /* font-family: 'Roboto', 'Arial', sans-serif; */
        width: 100%;
        padding: 0 1rem;
        /* prevents padding from affecting height and width */
        box-sizing: border-box;
        background-color: #fff;
        /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
        position: fixed;
        z-index: 10000;
    }

    #cs-navigation .cs-container {
        width: 100%;
        max-width: 80rem;
        margin: auto;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 1.5rem;
    }

    #cs-navigation .cs-toggle {
        display: none;
    }

    #cs-navigation .cs-logo {
        /* width: 18.4%; */
        /* max-width: 21.875rem; */
        height: 4.0625rem;
        /* margin-right auto pushes everything away from it to the right */
        margin: 0 auto 0 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 100;
    }

    #cs-navigation .cs-logo img {
        width: 100%;
        height: 100%;
        /* ensures the image never overflows the container. It stays contained within it's width and height and expands to fill it then stops once it reaches an edge */
        object-fit: contain;
    }

    #cs-navigation .cs-ul {
        width: 100%;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        /* 20px - 36px */
        gap: clamp(1.25rem, 2.6vw, 2.25rem);
    }

    #cs-navigation .cs-li {
        list-style: none;
        padding: 2rem 0;
        /* prevent flexbox from squishing it */
        flex: none;
    }

    #cs-navigation .cs-li-link {
        /* 14px - 16px */
        /* font-size: clamp(0.875rem, 1vw, 1rem); */
        line-height: 1.5em;
        text-decoration: none;
        margin: 0;
        color: var(--bodyTextColor);
        display: block;
        position: relative;
    }

    #cs-navigation .cs-li-link:hover:before {
        width: 100%;
    }

    #cs-navigation .cs-li-link.cs-active:before {
        width: 100%;
    }

    #cs-navigation .cs-li-link:before {
        /* active state underline */
        content: '';
        width: 0%;
        height: 2px;
        background: black;
        opacity: 1;
        position: absolute;
        display: block;
        bottom: 0rem;
        left: 0;
        transition: width .3s;
    }

    #cs-navigation .cs-button-solid {
        /* font-size: 1rem; */
        /* 46px - 56px */
        line-height: clamp(2.875em, 5.5vw, 3.5em);
        text-decoration: none;
        /* font-weight: 700; */
        text-align: center;
        margin: 0;
        color: #fff;
        min-width: 9.375rem;
        padding: 0 1.5rem;
        background-color: var(--primary);
        border-radius: 0.25rem;
        display: inline-block;
        position: relative;
        z-index: 1;
        /* prevents padding from adding to the width */
        box-sizing: border-box;
    }

    #cs-navigation .cs-button-solid:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 0%;
        background: #000;
        opacity: 1;
        top: 0;
        left: 0;
        z-index: -1;
        border-radius: 0.25rem;
        transition: width .3s;
    }

    #cs-navigation .cs-button-solid:hover:before {
        width: 100%;
    }
}