.blog-scroll-container {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    /* For momentum scrolling on iOS */
    scroll-behavior: smooth;

}

.container {
    max-width: 1360px !important;

}


.blog-post {
    flex: 0 0 auto;
    scroll-snap-align: start;
    /* additional styling as needed */
}

@keyframes fadeInOut {

    0%,
    100% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }
}

.fade-in-out {
    animation-name: fadeInOut;
    animation-timing-function: ease-in-out;
    animation-duration: 3s;
    /* Adjust as needed */
    animation-iteration-count: infinite;
}



/* .this-one {
    max-width: auto;
    margin-left: auto;
    margin-right: auto;
} */

@media (max-width: 992px) {
    #mb-for-mobile {
        margin-bottom: 2rem !important;
    }
}