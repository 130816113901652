/* Mobile - 1023px */
@media (max-width: 992px) {

    div#single-post-view-mobile {
        flex-direction: column-reverse;
    }

    div#blog-img {
        display: flex;
        justify-content: center;
        margin-bottom: 3rem;
    }

    img#blog-img-height {
        object-fit: 'cover';
        max-height: clamp(23rem, 6vw, 30rem);
    }

    h2#single-post-view-mobile-title {
        text-align: center;
    }

}